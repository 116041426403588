<template>
  <AuthLayout>
    <template v-slot:authContent>
      <!-- forgot password start -->
      <section class="row flexbox-container">
        <div class="col-xl-7 col-md-9 col-10  px-0">
          <div class="card bg-authentication mb-0">
            <div class="row m-0">
              <!-- left section-forgot password -->
              <div class="col-md-6 col-12 px-0">
                <div class="card disable-rounded-right mb-0 p-2">
                  <div class="card-header pb-1">
                    <div class="card-title">
                      <h4 class="text-center mb-2">Forgot Password?</h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <form class="mb-2">
                      <div v-if="step === 1">
                        <div class="text-muted text-center mb-2">
                          <small>
                            How should we deliver the verification code to you? Please, select a channel to get your verification code.
                          </small>
                        </div>
                        <ul class="text-center list-unstyled mb-0">
                          <li class="d-inline-block mr-2 mb-1">
                            <fieldset>
                              <div class="radio">
                                <input v-model="postForgotPasswordData.preferred_channel" value="email" type="radio" name="preferred_channel" id="email" checked="checked">
                                <label for="email">Email</label>
                              </div>
                            </fieldset>
                          </li>
                          <li class="d-inline-block mr-2 mb-1">
                            <fieldset>
                              <div class="radio">
                                <input v-model="postForgotPasswordData.preferred_channel" value="sms" type="radio" name="preferred_channel" id="sms">
                                <label for="sms">SMS</label>
                              </div>
                            </fieldset>
                          </li>
                        </ul>
                        <div class="text-danger" v-if="errors.preferredChannel ">{{ errors.preferredChannel }}</div>
                        <button @click="nextStepToSendCode" type="button" class="btn btn-primary glow position-relative w-100">Next<i id="icon-arrow-channel" class="bx bx-right-arrow-alt"></i></button>
                      </div>

                      <div v-if="step === 2">
                        <div class="text-muted text-center mb-2">
                          <small>
                            Enter the email or phone number, and we will send you a verification code.
                            (<a href="#" @click="prevStatus" title="Click to change preferred channel">Change Channel</a>)
                          </small>
                        </div>
                        <div class="form-group">
                          <div class="d-md-flex">
                            <VueMultiselect v-if="postForgotPasswordData.preferred_channel === 'sms'" v-model="selectedCountryCode" class="col-12 col-sm-12 p-0 m-0" :class="postForgotPasswordData.preferred_channel === 'sms' ? ' col-md-4' : 'col-md-12'"   :options="countryCode" :close-on-select="true" placeholder="Country Code" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                            <input v-model="username" type="text" class="form-control col-12 col-sm-12" :class="postForgotPasswordData.preferred_channel === 'sms' ? ' col-md-8' : 'col-md-12'" id="username" :placeholder="postForgotPasswordData.preferred_channel === 'email' ? 'Email Address' : 'Phone Number' " />
                          </div>
                          <div class="text-danger" v-if="errors.countryCode">{{ errors.countryCode }}</div>
                          <div class="text-danger" v-if="errors.username">{{ errors.username }}</div>
                        </div>
                        <button :disabled="username === ''" @click="submitForgotPassword" type="button" class="btn btn-primary glow position-relative w-100">SEND CODE<i id="icon-arrow-code" class="bx bx-right-arrow-alt"></i></button>
                      </div>

                      <div v-if="step === 3">
                        <div class="text-muted text-center mb-2">
                          <small>
                            {{ codeValidationStepMessage }}
                          </small>
                        </div>
                        <div class="form-group">
                          <input v-model="postForgotPasswordVerifyCodeData.code" type="text" class="form-control" id="code" placeholder="Verification Code" />
                          <div class="text-danger" v-if="errors.code">{{ errors.code }}</div>
                        </div>
                        <button @click="submitForgotPasswordVerifyCode"  type="button" class="btn btn-primary glow position-relative w-100 mb-1">Verify Code<i id="icon-arrow" class="bx bx-right-arrow-alt"></i></button>
                        <small>Resend code in {{ countDown }}s. <a v-if="countDown === 0" href="#" @click="resendCode">(Resend Code)</a></small>
                      </div>
                    </form>
                    <div class="text-center mb-2">
                      <router-link :to="{name: 'appLogin'}">
                        <small class="text-muted">I remembered my password</small>
                      </router-link>
                    </div>

                  </div>
                </div>
              </div>
              <!-- right section image -->
              <div class="col-md-6 d-md-block d-none text-center align-self-center">
                <img class="img-fluid" :src="forgotPasswordPageImage" alt="branding logo" width="300">
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- forgot password ends -->
    </template>
  </AuthLayout>
</template>

<script>


import AuthLayout from '@/layouts/backEnd/AuthLayout.vue'
import {mapActions} from "vuex";
import VueMultiselect from 'vue-multiselect';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
export default {
  name:"ForgotPassword",

  mixins: [ShowToastMessage, Loader],

  components: {
    AuthLayout,
    VueMultiselect,
  },

  props: ['initToastDataMessage', 'initToastDataType'],

  data() {
    return {
      forgotPasswordPageImage: '/app-assets/images/pages/forgot-password.png',
      step: 1,
      countDown: 60,
      username: '',
      codeValidationStepMessage: '',
      postForgotPasswordData: {
        preferred_channel: 'email',
        country_code: '',
        username: '',
      },

      postForgotPasswordVerifyCodeData: {
        username: '',
        code: '',
      },

      countryCode: [
        {
          name: 'AU (61)',
          value: '+61',
        },
      ],

      selectedCountryCode: {
        name: 'AU (61)',
        value: '+61',
      },

      errors: {
        username: '',
        countryCode: '',
        preferredChannel: '',
        code: '',
      }
    }
  },

  watch: {
    selectedCountryCode(selectedCountryCode) {
      this.postForgotPasswordData.country_code = selectedCountryCode.value;
    },

    username(username) {
      this.postForgotPasswordData.username = username;
      this.postForgotPasswordVerifyCodeData.username = username;
    }

  },

  methods: {
    ... mapActions({
      postForgotPassword: 'appForgotPassword/postForgotPassword',
      postForgotPasswordVerifyCode: 'appForgotPasswordVerifyCode/postForgotPasswordVerifyCode',
    }),

    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },

    nextStepToSendCode() {
      this.errors = {};
      this.step = 2;
    },

    nextStepToCodeValidation() {
      this.errors = {};
      this.step = 3;
      this.countDown = 60;
      this.countDownTimer();
    },

    async submitForgotPassword() {
      this.loader(true);
      await this.postForgotPassword(this.postForgotPasswordData).then(async (response) => {
        this.loader(false)

        if (response.status === 200) {
          this.codeValidationStepMessage = response.message;
          this.nextStepToCodeValidation();
        }

        this.errors.preferredChannel  = response.errors && response.errors.preferred_channel ? response.errors.preferred_channel[0] : '';
        this.errors.username          = response.errors && response.errors.username ? response.errors.username[0] : '';
        this.errors.countryCode       = response.errors && response.errors.country_code ? response.errors.country_code[0] : '';

        if (this.errors.preferredChannel) {
          this.step = 1;
        }
      });
    },

    async resendCode() {
      await this.submitForgotPassword();
      this.countDown = 60;
      this.countDownTimer();
    },

    async submitForgotPasswordVerifyCode() {
      this.loader(true);
      await this.postForgotPasswordVerifyCode(this.postForgotPasswordVerifyCodeData).then( async (response) => {
        this.loader(false);

        if (response.status === 200) {
          await this.$router.push({
            name: 'appResetPassword',
            params: {
              username: this.username,
            }
          });
        }

        this.errors.username  = response.errors && response.errors.username ? response.errors.username[0] : '';
        this.errors.code      = response.errors && response.errors.code ? response.errors.code[0] : '';

        if (this.errors.username) {
          this.step = 2;
        }

        if (response.message) {
          this.showToastMessage(response);
        }

      })
    },

    prevStatus() {
      return this.step --;
    },
  },

  async mounted() {
    if (this.initToastDataMessage) {
      await this.showToastMessage({message: this.initToastDataMessage, type: this.initToastDataType});
    }
  }
}
</script>

<style>

</style>